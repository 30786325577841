import { useEffect, useState } from 'react';
import { ScrollView, StatusBar, Text } from 'react-native';
import { styles } from '../styles';
import { cmsAPI } from '@utils/cmsApi';
import { SET_REDEMPTION_CODE_EXPIRATION, useAppModule } from '@modules/app.module';
import { Image } from 'react-native';
import { View } from 'react-native';
import WarningDisplay from '@components/WarningDisplay';
import RedeemCodeComponent from './RedeemCodeComponent';

export default function AccessDeniedComponent() {
  const [redeemCode, setRedeemCode] = useState(false);
  const { user, dispatch, logoutUser } = useAppModule();

  useEffect(() => {
    validateAccess();
  }, []);

  const validateAccess = async () => {
    try {
      const result = await cmsAPI.post(`${process.env.EXPO_PUBLIC_CMS}/api/smartstart/redeem`);
      const redemptionCodeExpirationTimestamp = result.data.redemptionCodeExpirationTimestamp;
      dispatch(
        SET_REDEMPTION_CODE_EXPIRATION({
          redemptionCodeExpirationTimestamp: redemptionCodeExpirationTimestamp,
        }),
      );
    } catch (error) {
      // noop
    }
  };

  const redeemCodeHandler = () => {
    setRedeemCode(true);
  };

  const userEmail = user?.user?.email;
  const warningMessage = [
    `Email ${userEmail} is not registered for the the current/upcoming semester. Please contact your Smart Start provider, or `,
    <a href="#" onClick={redeemCodeHandler}>click here</a>,
    " to enter your ECE Redemption Code.",
  ];

  if (redeemCode) {
    return <RedeemCodeComponent />;
  } else {
    return (
      <ScrollView style={styles.root}>
        <View style={styles.container}>
          <StatusBar barStyle="light-content" />

          <Image
            style={{
              width: 87,
              height: 41,
              alignSelf: 'center',
            }}
            source={require('./../../../../assets/images/logo.png')}
          />

          <WarningDisplay message={warningMessage} />

          <Text
            onPress={async () => {
              dispatch(logoutUser());
            }}
            style={styles.link}>
            Log out
          </Text>
        </View>
      </ScrollView>
    );
  }
}
