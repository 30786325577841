import React, { useState } from 'react';
import { Text, View, ScrollView, StyleSheet, StatusBar } from 'react-native';
import { StackProps } from '@navigator/stack';
import { colors } from '@theme';
import { useAppModule } from '@modules/app.module';
import ArticleListItem from '@components/ArticleListItem';
import SongListItem from '@components/SongListItem';
import { TouchableOpacity } from 'react-native-gesture-handler';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.lightGrayPurple,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    padding: 22,
    paddingTop: 0,
    marginHorizontal: 'auto',
  },
  title: {
    fontSize: 24,
    color: colors.smartPurple,
    marginTop: 20,
  },
  wrapper: {},
  subtitle: {
    fontSize: 14,
    color: colors.smartPurple,
  },
  buttonTitle: {
    fontSize: 16,
    color: colors.white,
    textAlign: 'center',
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: colors.lightPurple,
  },
  circle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: colors.smartPink,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    cursor: 'pointer',
  },
  circleTitle: {
    color: colors.white,
    textAlign: 'center',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 20,
    fontWeight: '900',
    lineHeight: 10,
    marginTop: 8,
  },
  circleTitleSmall: {
    color: colors.white,
    textAlign: 'center',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 12,
    fontWeight: '900',
    lineHeight: 10,
    marginTop: 8,
  },
  circleSubtitle: {
    color: colors.white,
    textAlign: 'center',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 7,
    fontWeight: '900',
    lineHeight: 20,
  },
  levelWrapper: {
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'flex-start',
  },
  notFound: {
    color: colors.smartPurple,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 20,
  },
});

export default function Home({ navigation }: StackProps) {
  const { feed, user, loading } = useAppModule();
  const currenUserLevel = user.user?.level?.id;
  const [activeLevel, setActiveLevel] = useState(currenUserLevel || feed?.levels[0]?.id);
  const level = feed?.levels?.find(lvl => lvl.id === activeLevel);
  const colorsArray = [
    colors.smartPink,
    colors.smartOrange,
    colors.smartYellow,
    colors.smartTeal,
    colors.smartPurple,
    colors.ltPink,
    colors.ltOrange,
    colors.ltYellow,
    colors.ltTeal,
    colors.ltPurple,
  ];

  window.dataLayer.push({ event: 'page_load', page: 'Home', level: level?.levelNumber });

  return (
    <ScrollView style={styles.root}>
      {!!level && (
        <View style={styles.container}>
          <StatusBar barStyle="light-content" />
          {/* Documentation: https://hossein-zare.github.io/react-native-dropdown-picker-website/docs */}
          <View style={styles.levelWrapper}>
            {feed?.levels?.map((l: any) => (
              <TouchableOpacity key={`level${l.levelNumber}`} onPress={() => setActiveLevel(l.id)}>
                <View
                  style={{
                    ...styles.circle,
                    backgroundColor:
                      l.id === activeLevel ? colorsArray[parseInt(l.levelNumber)] : colors.gray,
                  }}>
                  {l.levelNumber === '0' ? (
                    <Text style={styles.circleTitleSmall}>0-12</Text>
                  ) : (
                    <Text style={styles.circleTitle}>{l.levelNumber}</Text>
                  )}
                  <Text style={styles.circleSubtitle}>
                    {l.levelNumber === '0' ? 'MON' : l.levelNumber === '1' ? 'YR' : 'YRS'}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>

          {level?.feed?.map((feedItem, jindex) => {
            const article = feedItem?.article;
            const song = feedItem?.song;
            const songaudio = song?.audio?.url;

            return (
              <React.Fragment key={jindex}>
                {article && (
                  <ArticleListItem
                    subtitle={true}
                    theme={'home'}
                    key={`${jindex}-article`}
                    navigation={navigation}
                    article={article}
                  />
                )}
                {songaudio && (
                  <SongListItem
                    color={colors.smartPurple}
                    subtitle={true}
                    key={`${jindex}-song`}
                    navigation={navigation}
                    song={song}
                  />
                )}
              </React.Fragment>
            );
          })}
          {level?.feed.length === 0 && <Text style={styles.notFound}>No content found</Text>}
        </View>
      )}
      {!loading && !level && <Text style={styles.notFound}>Content loading</Text>}
    </ScrollView>
  );
}
