import { useState } from 'react';
import { ScrollView, StatusBar, Text } from 'react-native';
import { styles } from '../styles';
import LabeledTextInput from '@components/LabeledTextInput';
import Button from '@components/Button';
import { cmsAPI } from '@utils/cmsApi';
import ErrorDisplay from '@components/ErrorDisplay';
import MessageDisplay from '@components/MessageDisplay';
import {
  SET_REDEMPTION_CODE,
  SET_REDEMPTION_CODE_EXPIRATION,
  useAppModule,
} from '@modules/app.module';
import { Image } from 'react-native';
import { View } from 'react-native';
import WarningDisplay from '@components/WarningDisplay';

export default function RedeemCodeComponent() {
  const { user, dispatch, logoutUser } = useAppModule();

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [code, onChangeCode] = useState('');

  const handleRedeemCode = async () => {
    setError('');
    setMessage('');
    try {
      const result = await cmsAPI.post(`${process.env.EXPO_PUBLIC_CMS}/api/smartstart/redeem`, {
        code: code,
      });

      const redemptionCode = result.data.code;
      const redemptionCodeExpirationTimestamp = result.data.redemptionCodeExpirationTimestamp;
      setMessage(
        `Code ${redemptionCode} redeemed successfully. Your code is valid until ${new Date(
          redemptionCodeExpirationTimestamp,
        ).toLocaleDateString()}`,
      );
      dispatch(SET_REDEMPTION_CODE({ code: redemptionCode }));
      dispatch(
        SET_REDEMPTION_CODE_EXPIRATION({
          redemptionCodeExpirationTimestamp: redemptionCodeExpirationTimestamp,
        }),
      );
    } catch (error) {
      const msg =
        error?.response?.data?.error?.details?.errors?.[0]?.message ||
        error?.response?.data?.error?.message ||
        'Code redemption failed';
      setError(msg);
    }
  };

  const isFirstLogin = user?.user?.redemptionCodeExpirationTimestamp == 0;
  const warningMessage = isFirstLogin
    ? 'To start using the app, please redeem your Smart Start code.'
    : 'To continue using the app, please redeem your Smart Start code.';

  return (
    <ScrollView style={styles.root}>
      <View style={styles.container}>
        <StatusBar barStyle="light-content" />

        <Image
          style={{
            width: 87,
            height: 41,
            alignSelf: 'center',
          }}
          source={require('./../../../../assets/images/logo.png')}
        />

        <WarningDisplay message={warningMessage} />

        <Text style={styles.title}>Redeem Smart Start code</Text>

        {!!message && <MessageDisplay message={message} />}
        {!!error && <ErrorDisplay message={error} />}

        <LabeledTextInput
          label="Code"
          style={styles.input}
          isMandatory={true}
          onChangeText={onChangeCode}
          placeholder="XXXXXXXXXXXXXXXX"
          keyboardType="default"
        />

        <Button
          title="Redeem code"
          titleStyle={styles.buttonTitle}
          style={styles.button}
          disabled={!code}
          onPress={handleRedeemCode}
        />

        <Text
          onPress={async () => {
            dispatch(logoutUser());
          }}
          style={styles.link}>
          Log out
        </Text>
      </View>
    </ScrollView>
  );
}
