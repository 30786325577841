import React from 'react';
import { Text } from 'react-native';
import { colors } from '@theme';

export default function WarningDisplay({ message }: { message: string | (string | React.JSX.Element)[] }) {

  return (
    <Text
      style={{
        color: colors.black,
        backgroundColor: colors.smartYellow,
        marginVertical: 32,
        padding: 15,
        borderRadius: 6,
      }}>
      {message}
    </Text>
  );
}
