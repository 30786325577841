import { Text } from 'react-native';
import { styles } from '../styles';
import LabeledTextInput from '@components/LabeledTextInput';
import Button from '@components/Button';
import { useState } from 'react';
import ErrorDisplay from '@components/ErrorDisplay';
import { cmsAPI } from '@utils/cmsApi';
import LoginComponent from './LoginComponent';
import { useAppModule } from '@modules/app.module';
import MessageDisplay from '@components/MessageDisplay';

export default function ResetPasswordComponent({  }) {
  const { dispatch, loginUser } = useAppModule();
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  
  const [password, onChangePassword] = useState('');
  const [repeatPassword, onChangeRepeatPassword] = useState('');


  const handlePasswordReset = async () => {
    setError('');
    if (password !== repeatPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const result = await cmsAPI.post(`${process.env.EXPO_PUBLIC_CMS}/api/auth/reset-password`, {
        code: code,
        password: password,
        passwordConfirmation: repeatPassword,
      });
      //dispatch login user
      await dispatch(loginUser(result.data));


    } catch (error) {
      setError(error?.response?.data?.error?.message || 'Password reset failed');
    }
  };

  return (
    <>
      <MessageDisplay message={`Password reset code has been sent to the provided email. Please enter the code and set the new password.`} />

      {!!error && <ErrorDisplay message={error} />}

      <LabeledTextInput
        label="Code"
        style={styles.input}
        value={code}
        onChangeText={setCode}
        isMandatory={true}
        placeholder="Email code"
        keyboardType="default"
      />

      <LabeledTextInput
        label="New password"
        style={styles.input}
        value={password}
        onChangeText={onChangePassword}
        isMandatory={true}
        placeholder="Password"
        secureTextEntry={true}
        keyboardType="default"
      />
      <Text
        style={{
          color: 'white',
          fontSize: 12,
          marginBottom: 0,
          marginTop: 0,
          padding: 1.5,
        }}>
        at least 8 characters, one special character, one number, and one capital letter
      </Text>

      <LabeledTextInput
        label="Repeat password"
        style={styles.input}
        value={repeatPassword}
        onChangeText={onChangeRepeatPassword}
        isMandatory={true}
        placeholder="Password"
        secureTextEntry={true}
        keyboardType="default"
      />

      <Button
        title="Reset"
        titleStyle={styles.buttonTitle}
        style={styles.button}
        disabled={!code || !password || !repeatPassword}
        onPress={handlePasswordReset}
      />
    </>
  );
}
