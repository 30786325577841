import React, { useState } from 'react';
import { Text, View, StatusBar, ScrollView } from 'react-native';
import Button from '@components/Button';
import { useAppModule, SET_USER_LEVEL } from '@modules/app.module';
import DropDownPicker from 'react-native-dropdown-picker';
import { cmsAPI } from '@utils/cmsApi';
import LabeledTextInput from '@components/LabeledTextInput';
import { styles } from './styles';
import HorizontalLine from '@components/HorizontalLine';
import ErrorDisplay from '@components/ErrorDisplay';
import MessageDisplay from '@components/MessageDisplay';
import { colors } from '@theme';

export default function Preferences() {
  const { user, feed, dispatch, logoutUser } = useAppModule();
  const [open, setOpen] = useState(false);
  const [studentLevel, onChangeStudentLevel] = useState(user?.user?.level?.id);

  const [changePasswordError, setChangePasswordError] = useState('');
  const [changePasswordMessage, setChangePasswordMessage] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      setChangePasswordError('Please fill all fields');
      return;
    }

    if (newPassword !== confirmPassword) {
      setChangePasswordError('New password and confirm password do not match');
      return;
    }

    try {
      await cmsAPI.post(`${process.env.EXPO_PUBLIC_CMS}/api/auth/change-password`, {
        currentPassword: currentPassword,
        password: newPassword,
        passwordConfirmation: confirmPassword
      });

      setChangePasswordMessage('Password changed successfully');
      setChangePasswordError('');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setChangePasswordError(err?.response?.data?.error?.message || 'Password change failed');
      return;
    }

  };

  const expirationTimestamp = user?.user?.redemptionCodeExpirationTimestamp * 1;

  return (
    <ScrollView style={styles.root}>
      <View style={styles.container}>
        <StatusBar barStyle="light-content" />

        <Text style={styles.title}>Account info</Text>
        {feed?.levels && (
          <View>
            <Text
              style={{
                color: colors.white,
                fontSize: 12,
                marginBottom: 0,
                marginTop: 20,
                padding: 1.5,
              }}>
              Student's age*
            </Text>
            <DropDownPicker
              open={open}
              value={studentLevel}
              items={feed?.levels?.map((l: any) => ({ label: l?.title, value: l.id }))}
              setOpen={setOpen}
              setValue={(value: any) => {
                const val = value();
                onChangeStudentLevel(val);
                try {
                  cmsAPI.patch(`${process.env.EXPO_PUBLIC_CMS}/api/smartstart/user`, {
                    data: { level: val },
                  });
                  dispatch(SET_USER_LEVEL({ level: { id: val } }));
                } catch (err) {}
              }}
              placeholder="Select age"
            />
          </View>
        )}

        <HorizontalLine />
        <Text style={styles.title}>App Access</Text>

        <Text style={{ color: colors.white, marginTop: 5, padding: 1.5 }}>
          Expiration date:{' '}
          {(expirationTimestamp > 0 && new Date(expirationTimestamp).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })) || 'N/A'}
                  </Text>

        <HorizontalLine />
        <Text style={styles.title}>Change Password</Text>

        {!!changePasswordMessage && <MessageDisplay message={changePasswordMessage} />}
        {!!changePasswordError && <ErrorDisplay message={changePasswordError} />}

        <LabeledTextInput
          label="Current Password"
          isMandatory={true}
          secureTextEntry={true}
          onChangeText={setCurrentPassword}
          placeholder="Current password"
          value={currentPassword}
          style={styles.input}
        />
        <LabeledTextInput
          label="New Password"
          isMandatory={true}
          secureTextEntry={true}
          onChangeText={setNewPassword}
          placeholder="New password"
          value={newPassword}
          style={styles.input}
        />
        <LabeledTextInput
          label="Confirm Password"
          isMandatory={true}
          secureTextEntry={true}
          onChangeText={setConfirmPassword}
          placeholder="Confirm new password"
          value={confirmPassword}
          style={styles.input}
        />
        <Button
          title="Change Password"
          titleStyle={styles.buttonTitle}
          style={styles.button}
          disabled={!currentPassword || !newPassword || !confirmPassword || !!changePasswordMessage}
          onPress={handleChangePassword}
        />

        <HorizontalLine />
        <Button
          title="Logout"
          titleStyle={styles.buttonTitle}
          style={styles.button}
          onPress={async () => {
            dispatch(logoutUser());
          }}
        />
      </View>
    </ScrollView>
  );
}
