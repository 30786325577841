import { Text } from 'react-native';
import { styles } from '../styles';
import LabeledTextInput from '@components/LabeledTextInput';
import Button from '@components/Button';
import { useState } from 'react';
import ErrorDisplay from '@components/ErrorDisplay';
import { cmsAPI } from '@utils/cmsApi';
import MessageDisplay from '@components/MessageDisplay';
import { set } from '@gluestack-style/react';
import ResetPasswordComponent from './ResetPasswordComponent';

export default function ForgotPasswordComponent() {
  const [error, setError] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [email, onChangeEmail] = useState('');

  const handlePasswordReset = async () => {
    try {
      setError('');
      const isValidEmail = email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
      if (!isValidEmail) {
        setError('Please enter a valid email');
        return;
      }
      cmsAPI.post(`${process.env.EXPO_PUBLIC_CMS}/api/auth/forgot-password-mobile`, {
        email: email,
      });
      setCodeSent(true);
    } catch (error) {
      setError(error?.response?.data?.error?.message || 'Password reset failed');
    }
  };

  return (
    <>
      <Text style={styles.title}>Reset password</Text>
      {!!error && <ErrorDisplay message={error}/>}

      {codeSent && 
        <>
          <ResetPasswordComponent />
        </>
      }

      {!codeSent && 
      <>
        <LabeledTextInput
          label="Email address"
          style={styles.input}
          isMandatory={true}
          value={email}
          onChangeText={onChangeEmail}
          placeholder="john.doe@example.com"
        />

        <Button
          title="Send reset code"
          titleStyle={styles.buttonTitle}
          style={styles.button}
          disabled={!email}
          onPress={handlePasswordReset}
        />
      </>}

    </>
  );
}
